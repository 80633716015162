import React from "react";
import "../../App.css";
import "../../styles.scss";
import "react-slideshow-image/dist/styles.css";
import { Grid, Typography, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { IconButton } from "@mui/material";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import DiscountIcon from "@mui/icons-material/Discount";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import SettingsInputHdmiIcon from '@mui/icons-material/SettingsInputHdmi';
import WifiIcon from '@mui/icons-material/Wifi';
function Services() {
  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      id="services"
      sx={{
        width: "100%",
        height:{sm:'auto',md:'auto', lg:"107vh",xl:"100vh"},
        margin: "auto",
       backgroundImage:'linear-gradient(180deg,#7df8fb 0%, #dbf912 100%)',
        padding: "20px",
        alignContent: 'flex-end',
      }}
      // data-aos="fade-up"
      // data-aos-duration="900"
    >
      <Grid
        item
        container
        justifyContent="space-around"
        alignItems="center"
        flexDirection="column"
        xs={12}
      >

        <Grid item>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, textAlign: "center", color: "#346e86" }}
          >
            NUESTROS SERVICIOS
          </Typography>
        
          <hr style={{ border: "2px solid rgb(207 255 157)" }} />
        </Grid>
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <Card
          sx={{
            width: "95%",
            height: {lg:"400px", md:"360px",sm:'auto',xs:'auto'},
            margin: "auto",
            padding: "10px",
            backgroundColor: "rgb(0 138 156 / 61%)",
            // backgroundColor: 'rgb(1 220 248 / 74%)',
            borderRadius: "15px",
            mb:{xs:'15px',sm:'15px', md:'15px'},
          }}
          elevation={10}
        >
          <CardContent>
            <Grid
              container
              justifyContent="space-around"
              alignItems="center"
              flexDirection="column"
            >
              <Grid item>
                <IconButton sx={{ backgroundColor: "white" }}>
                  <SettingsInputHdmiIcon  sx={{ color: "#93ca62" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ textAlign: "center", color: "white" }}
                  gutterBottom
                >
                  {/* Internet Residencial */}
                  Fibra Óptica
                </Typography>
                <hr style={{ border: "2px solid #d8fc55ab" }} />
              </Grid>
            </Grid>

            <Typography variant="h6" component="div" sx={{ color: "white", textAlign:'justify'  }}>
              <LeakAddIcon sx={{ color: "white", verticalAlign: "middle"}} />{" "}
              Servicio de Internet para su hogar o empresa con Fibra óptica
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: "white", textAlign:'justify'  }}>
              <ElectricBoltIcon
                sx={{ color: "white", verticalAlign: "middle"  }}
              />{" "}
              Con velocidades desde 10 Mbps.
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: "white", textAlign:'justify'  }}>
              <FamilyRestroomIcon
                sx={{ color: "white", verticalAlign: "middle" }}
              />{" "}
              Conéctate con tu familia y amigos con navegación ilimitada.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <Card
          sx={{
            width: "95%",
            height: {lg:"400px", md:"360px",sm:'auto',xs:'auto'},
            margin: "auto",
            backgroundColor: "rgb(0 138 156 / 61%)",
            // backgroundColor: 'rgb(1 220 248 / 74%)',
            mb:{xs:'15px',sm:'15px', md:'15px'},
            borderRadius: "15px",
          }}
          elevation={10}
        >
          <CardContent>
            <Grid
              container
              justifyContent="space-around"
              alignItems="center"
              flexDirection="column"
            >
              <Grid item>
                <IconButton sx={{ backgroundColor: "white" }}>
                  <WifiIcon sx={{ color: "#93ca62" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ textAlign: "center", color: "white" }}
                  gutterBottom
                >
                 Redes Microondas
                </Typography>
                <hr style={{ border: "2px solid #d8fc55ab" }} />
              </Grid>
            </Grid>

            <Typography variant="h6" component="div" sx={{ color: "white", textAlign:'justify' }}>
              <LeakAddIcon sx={{ color: "white", verticalAlign: "middle", marginRight:"5px"  }} />
              Servicio dedicado y compartido para su empresa u hogar con Redes Microondas.
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: "white", textAlign:'justify' }}>
              <ElectricBoltIcon
                sx={{ color: "white", verticalAlign: "middle" }}
              />{" "}
              Con velocidades desde de 5120 Kbps.
            </Typography>
             <Typography variant="h6" component="div" sx={{ color: "white", textAlign:'justify'  }}>
              <WifiIcon sx={{ color: "white", verticalAlign: "middle" }} />{" "}
             Alta calidad de señal.
            </Typography> 
          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={3} md={6} sm={12} xs={12}>
        <Card
          sx={{
            width: "95%",
            height: {lg:"400px", md:"360px",sm:'auto',xs:'auto'},
            margin: "auto",
            backgroundColor: "rgb(0 138 156 / 61%)",
            // backgroundColor: 'rgb(1 220 248 / 74%)',
            mb:{xs:'15px',sm:'15px', md:'15px'},
            borderRadius: "15px",
          }}
          elevation={10}
        >
          <CardContent>
            <Grid
              container
              justifyContent="space-around"
              alignItems="center"
              flexDirection="column"
            >
              <Grid item>
                <IconButton sx={{ backgroundColor: "white", textAlign:'justify' }}>
                  <AccountTreeIcon sx={{ color: "#93ca62" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ textAlign: "center", color: "white" }}
                  gutterBottom
                >
                  Redes de Datos
                </Typography>
                <hr style={{ border: "2px solid #d8fc55ab" }} />
              </Grid>
            </Grid>

            <Typography variant="h6" component="div" sx={{ color: "white", textAlign:'justify' }}>
              <DeviceHubIcon sx={{ color: "white", verticalAlign: "middle", marginRight:"5px" }} />
              Le proveemos su red de datos.
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: "white", textAlign:'justify' }}>
              <ConnectedTvIcon
                sx={{ color: "white", verticalAlign: "middle", marginRight:"5px" }}
              />
              Interconexión de sus computadoras.
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: "white", textAlign:'justify' }}>
              <SupervisorAccountIcon
                sx={{ color: "white", verticalAlign: "middle" , marginRight:"5px"}}
              />
              Mantenemos su red y se la administramos.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <Card
          sx={{
            width: "95%",
            height: {lg:"400px", md:"360px",sm:'auto',xs:'auto'},
            margin: "auto",
            backgroundColor: "rgb(0 138 156 / 61%)",
            // backgroundColor: 'rgb(1 220 248 / 74%)',
            mb:{xs:'15px',sm:'15px', md:'15px'},
            borderRadius: "15px",
          }}
          elevation={10}
        >
          <CardContent>
            <Grid
              container
              justifyContent="space-around"
              alignItems="center"
              flexDirection="column"
            >
              <Grid item>
                <IconButton sx={{ backgroundColor: "white" }}>
                  <PermPhoneMsgIcon sx={{ color: "#93ca62" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ textAlign: "center", color: "white" }}
                  gutterBottom
                >
                  Asesoramiento
                </Typography>
                <hr style={{ border: "2px solid #d8fc55ab" }} />
              </Grid>
            </Grid>

            <Typography variant="h6" component="div" sx={{ color: "white", textAlign:'justify' }}>
              <AccessibilityNewIcon
                sx={{ color: "white", verticalAlign: "middle", marginRight:"5px" }}
              />
              Le asesoramos en el mejor plan de Internet a elegir. Siéntase
              seguro y confiable al momento de adquirir nuestros servicios.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Services;
