import React from "react";
import "../../App.css";
import "../../styles.scss";
import "react-slideshow-image/dist/styles.css";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import CabinIcon from "@mui/icons-material/Cabin";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
function OurTeam() {
  const bg=require("../../asset/technology.jpg");
  return (
    <Box
      sx={{
        width: "100%",
        height: { lg: "20vh", md: "20vh", sm: "auto", xs: "auto" },
        background: "white",
        p:{
          xs:'10px', sm:'30px', md:'0px',
        }
      }}
      
    >
      <Box
        sx={{
          width: "100%",
          p: 2,
          height: { lg: "20vh", md: "auto", sm: "auto", xs: "auto" },
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <IconButton
                  sx={{
                    background: "linear-gradient(to right,#5ec47d, #265370)",
                  }}
                >
                  <BusinessIcon sx={{ color: "white" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{ color: "white", fontWeight: 600 }}
                >1000+ </Typography>
               
              </Grid>
              <Grid item>
                <span className="title"> Clientes Empresariales</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {" "}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <IconButton
                  sx={{
                    background: "linear-gradient(to right,#5ec47d, #265370)",
                  }}
                >
                  <CabinIcon sx={{ color: "white" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{ color: "white", fontWeight: 600 }}
                >
                  2000+
                </Typography>
              </Grid>
              <Grid item>
                
                <span className="title"> Clientes Residenciales</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {" "}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <IconButton
                  sx={{
                    background: "linear-gradient(to right,#5ec47d, #265370)",
                  }}
                >
                  <RocketLaunchIcon sx={{ color: "white" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{ color: "white", fontWeight: 600 }}
                >
                  11+
                </Typography>
              </Grid>
              <Grid item>
              
                <span className="title">Años de Experiencia </span>

              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {" "}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <IconButton
                  sx={{
                    background: "linear-gradient(to right,#5ec47d, #265370)",
                  }}
                >
                  <MilitaryTechIcon sx={{ color: "white" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{ color: "white", fontWeight: 600 }}
                >
                  100+
                </Typography>
              </Grid>
              <Grid item>
              <span className="title">Instalación por mes </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default OurTeam;
