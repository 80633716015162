import React from "react";
import { Box, Grid, Typography } from "@mui/material";
interface notice {
//   id: string;
  title: string;
  image: string;
  description: string;
}
function Notice(props: notice) {


  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{mb:{xs:'50px', sm:'50px',md:'20px',}}}
      >
        
        <Grid item sx={{display:'flex', margin:'auto',padding:{xs:'5px',sm:'5px', md:'20px',lg:'35px', background: '#002856',borderRadius:'10px'}}}>
          <img
            src={props.image}
            alt="noticias imagen"
            style={{ width: "100%", height: "50vh", margin:'auto' }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5" sx={{textAlign:'center'}}>{props.title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" sx={{textAlign:'center'}}>{props.description}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Notice;
