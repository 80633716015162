import React from 'react';
import {Card, Grid, Typography, Tooltip, CardContent, IconButton, CardActions, Button} from '@mui/material';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import { HashLink } from 'react-router-hash-link';

interface card{
    ICon:any;
    name:string;
    up: number;
    down:number;
    price:string;
    //id:string;
}

function CardC(props:card) {
  const bgCard= require('../../asset/bg-card.jpg');
    return (
        <Card sx={{ maxWidth: 300, margin: "auto", mb:{sm:"15px", xs:'15px', md:'15px'} }} elevation={10}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          sx={{
            backgroundImage: `url(${bgCard})`,
            
            width: "100%",
            height: "100px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            flexWrap: "nowrap",
            padding: "10px",
          }}
        >
          <Grid item>
            <props.ICon  />
            {props.ICon}
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ color: "white", fontWeight: 700, textAlign:'center' }}>
              {props.name}
            </Typography>
          </Grid>
        </Grid>

        <CardContent>
          <Typography
            gutterBottom
            variant="h3"
            sx={{ textAlign: "center", mb: "0px", color: "#346e86" }}
          >
            {props.down}
          </Typography>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", color: "#346e86" }}
          >
            Mbps
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "#346e86" }}
          >
         { `${props.down} Mbps`}
            <Tooltip title="Descarga">
              <IconButton>
                <FileDownloadIcon
                  sx={{
                    verticalAlign: "middle",
                    color: "rgb(54 237 74 / 89%)",
                    fontSize: "30px",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "#346e86" }}
          >
             { `${props.up} Mbps`}
            <Tooltip title="Subida">
              <IconButton>
                <FileUploadIcon
                  sx={{
                    verticalAlign: "middle",
                    color: "rgb(54 237 74 / 89%)",
                    fontSize: "30px",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography
            gutterBottom
            variant="h4"
            sx={{ textAlign: "center", mb: "0px", color: "#346e86", fontWeight:600 }}
          >
           {`$${props.price}`}
          </Typography>
          <Typography 
           gutterBottom
           variant="body1"
           sx={{ textAlign: "center", mb: "0px", color: "#346e86", fontWeight:400 }}>
            Pago Mensual
          </Typography>
        </CardContent>
        <CardActions>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <HashLink to="/contactPlans">
                  <Button
                size="large"
                variant="outlined"
                sx={{ color: "#346e86", border: "1px solid #346e86" }}
              >
                Obtener
              </Button>
              </HashLink>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    );
}

export default CardC;