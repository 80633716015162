import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import BackupIcon from "@mui/icons-material/Backup";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import TvIcon from '@mui/icons-material/Tv';
// import img from '../../asset/conexion-adslsystem.jpg';
function OtherServices() {
  const img = require('../../asset/conexion-adslsystem.jpg');
  return (
    <Grid
    id="moreservices"
      container
      sx={{
        width: "100%",
        height: {lg:"100vh", md:'auto',sm:'auto',xs:'auto'},
        background: "white",
        alignContent: " center",
        justifycontent: "center",
        alignItems: "center",
        justifyContent: "center",
        p:{
          xs:'10px', sm:'50px', md:'20px',
        }
      }}
      data-aos="fade-right"
     data-aos-offset="400"
    >
      <Grid item lg={4} md={6} sm={12} xs={12} sx={{paddingRight:{lg:'20px',md:'10px'}}}>
        <Typography variant="h4" sx={{color:'rgb(0, 138, 156)'}}>¿QUÉ MÁS OFRECEMOS?</Typography>
        <hr style={{ border: "2px solid rgb(0, 138, 156)" }} />
        <Grid container sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
          <Grid item>
            <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
              <SettingsInputAntennaIcon
                sx={{
                  color: "white",
                  fontSize: "2rem",
                  verticalAlign: "middle",
                }}
              />
            </IconButton>{" "}
          </Grid>
          <Grid item>
            <Typography sx={{fontWeight:600, color:"rgb(0, 138, 156)"}}> Zonas wifi</Typography>
            <Typography>
              No tienes internet en tus eventos? Contrata nuestros servicios de
              wifi permanente o Leasing.{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid container  sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
          <Grid item>
            <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
              <CameraIndoorIcon 
                sx={{
                  color: "white",
                  fontSize: "2rem",
                  verticalAlign: "middle",
                }}
              />
            </IconButton>{" "}
          </Grid>
          <Grid item>
            <Typography sx={{fontWeight:600, color:"rgb(0, 138, 156)"}}> CCTV</Typography>
            <Typography>
            Proyectos e instalación de CCTV para su hogar y empresa, con tecnología HD e IP.
            </Typography>
          </Grid>
        </Grid>

        <Grid container  sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
          <Grid item>
            <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
              <BackupIcon
                sx={{
                  color: "white",
                  fontSize: "2rem",
                  verticalAlign: "middle",
                }}
              />
            </IconButton>{" "}
          </Grid>
          <Grid item>
            <Typography sx={{fontWeight:600, color:"rgb(0, 138, 156)"}}> DNS</Typography>
            <Typography>
             Adquiera su IP pública fija para monitorear sus sistemas web, renovable cada 12 meses.
            </Typography>
          </Grid>
        </Grid>
        <Grid container  sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
          <Grid item>
            <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
              <TvIcon
                sx={{
                  color: "white",
                  fontSize: "2rem",
                  verticalAlign: "middle",
                }}
              />
            </IconButton>{" "}
          </Grid>
          <Grid item>
            <Typography sx={{fontWeight:600, color:"rgb(0, 138, 156)"}}>Streaming</Typography>
            <Typography>
             Adquiera nuestro servicio de streaming y disfrute de películas y series.
            </Typography>
          </Grid>
        </Grid>
        <Grid container  sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
          <Grid item>
            <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
              <LiveTvIcon
                sx={{
                  color: "white",
                  fontSize: "2rem",
                  verticalAlign: "middle",
                }}
              />
            </IconButton>{" "}
          </Grid>
          <Grid item>
            <Typography sx={{fontWeight:600, color:"rgb(0, 138, 156)"}}>Televisión por cable</Typography>
            <Typography>
            Pronto estaremos contando con servicio de Televisión por cable.
            </Typography>
          </Grid>
        </Grid>
        
      </Grid>
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <img
          src={img}
          alt="conexion adsl"
          style={{ width: "100%" }}
        />
      </Grid>
    </Grid>
  );
}

export default OtherServices;
