import React from "react";
import { Paper, Grid, Typography, Button, InputLabel, TextField, Select } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps,AlertColor } from "@mui/material/Alert";
import axios from 'axios';
function Form() {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [open, setOpen] = React.useState(false);
  const [textSnack, setTextSnack] = React.useState<string>(" ");
  const [severity, setSeverity] = React.useState<AlertColor>();



  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  function Snackbars() {
    return (
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {textSnack}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }
  interface formProps {
    ci: string | null;
    full_name: string;
    doctype: string | null;
    email: string;
    phone: any;
    direction: string;
    plan_id: string;
    message: string;
  }
  const schema: any = yup.object({
    ci: yup.string().required('Este campo es requerido'),
    full_name: yup.string().required('Este campo es requerido'),
    doctype: yup.string().required('Este campo es requerido'),
    email: yup.string().email('Formato invalido').required('Este campo es requerido'),
    phone: yup.string().min(10).max(15).required('Este campo es requerido'),
    direction: yup.string().required('Este campo es requerido'),
    plan_id: yup.string().required('Este campo es requerido'),
    message: yup.string(),
  });
  const onSubmit = (data: any) => {
    console.log(data);
    postcontact(data);
  };
  const [plan, setPlan] = React.useState([]);
  async function plansInquiry() {
    return axios 
      .get(`plans/`)
      .then((response) => {
        setPlan(response.data.results);
        console.log(response.data);
      })
      .catch((error) => console.log(error));
  }
  React.useEffect(() => {
    plansInquiry();
    // eslint-disable-next-line
  }, []);

  const postcontact = async ( data: formProps) => {
    return axios
      .post(`services/`, data)
      .then((response) => {
        setTextSnack('Se ha enviado de manera exitosa');
        setSeverity("success");
        handleClick();
        reset();
        return response.data;
        
      })
      .catch((error) => {
        console.log(error);
        setTextSnack('ha ocurrido un error, verifique su conexión a internet');
        setSeverity("error");
        handleClick();
      });
  };
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<formProps>({
    defaultValues:{
       ci:'',
      full_name:'',
      doctype: '',
      email: '',
      phone: 0,
      direction:'',
      plan_id:'',
      message: '',
},
    resolver: yupResolver(schema),
  });

  return (
    <Paper
      sx={{
        width:{lg:"60%", md:'70%', sm:'100%', xs:'100%'},
        height: "auto",
        m: "auto",
        p: 5,
        zIndex: 2,
        // background: "#7df8fb96",
        background: "rgb(25 118 210 / 50%)",
        mt:{lg:'100px', md:'100px',sm:'100px',sx:'100px'}
      }}
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ textAlign: "center",color:"white" }}>
              Contáctenos para Adquirir un Plan
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: 600,color:"white"}}>
              NOMBRE COMPLETO*
            </InputLabel>
            
                <input
                 {...register('full_name')}
                 name="full_name"
                  style={{
                    width: "98%",
                    borderRadius: "50px",
                    height: "50px",
                    padding: "10px",
                    background: "#7df8fb96",
                    border: "none",
                    margin: "15px auto",
                  }}
                 
        
                  type="text"
                //   required
                //   title="Por favor ingrese su nombre y apellido"
                />
              {errors.full_name && (
          <p>{errors.full_name.message}</p>
        )}
           
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel sx={{ fontWeight: 600,color:"white" }}>
                  TIPO DE DOCUMENTO*
                </InputLabel>
                <select
                // name="doctype"
                      style={{
                        width: "95%",
                        borderRadius: "50px",
                        height: "50px",
                        padding: "10px",
                        background: "#7df8fb96",
                        border: "none",
                        margin: "15px auto",
                      }}
                     {...register('doctype')}
                    //  onChange={onchange} // send value to hook form
                    >
                        <option value="Cédula Venezolana" key="v" >
                        Cédula Venezolana
                      </option>
                      <option value="Pasaporte" key="pe">Pasaporte</option>
                      <option value="Cédula Extranjero" key="e">
                        {" "}
                        Cédula Extranjero
                      </option>
                      <option value="R.I.F Natural" key="vn">R.I.F Natural</option>
                      <option value="R.I.F Jurídico" key="j">R.I.F Jurídico</option>
                      <option value="R.I.F Extranjero" key="er">R.I.F Extranjero</option>
                      <option value="R.I.F Comuna" key="c">R.I.F Comuna</option>
                      <option value="R.I.F Gobierno" key="g">R.I.F Gobierno</option>
                    </select>
                    {errors.doctype && (
          <p>{errors.doctype.message}</p>
        )}
              
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                 <InputLabel sx={{ fontWeight: 600,color:"white" }}>
                  C.I/R.I.F*
                </InputLabel>
                <input
                 {...register('ci')}
                 name="ci"
                  style={{
                    width: "95%",
                        borderRadius: "50px",
                        height: "50px",
                        padding: "10px",
                        background: "#7df8fb96",
                        border: "none",
                        margin: "15px auto",
                  }}
                  type="text"
                />
              {errors.ci && (
          <p>{errors.ci.message}</p>
        )}
            
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                 <InputLabel sx={{ fontWeight: 600,color:"white"}}>
                  EMAIL*
                </InputLabel>
                <input
                {...register('email')}
                      style={{
                        width: "95%",
                        borderRadius: "50px",
                        height: "50px",
                        padding: "10px",
                        background: "#7df8fb96",
                        border: "none",
                        margin: "15px auto",
                      }}
                      type="email"
                    />
                    {errors.email && (
          <p>{errors.email.message}</p>)}
             
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel sx={{ fontWeight: 600,color:"white" }}>
             NÚMERO DE TELÉFONO*
                </InputLabel>
                <input
                {...register('phone')}
                      style={{
                        width: "95%",
                        borderRadius: "50px",
                        height: "50px",
                        padding: "10px",
                        background: "#7df8fb96",
                        border: "none",
                        margin: "15px auto",
                      }}
                     
                    />
                       {errors.phone && (
          <p>Este campo es requerido y el número debe ser igual o mayor a 11 caracteres</p>
        )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel sx={{ fontWeight: 600,color:"white" }}>
                  DIRECCION*
                </InputLabel>
                <input
                      style={{
                        width: "95%",
                        borderRadius: "50px",
                        height: "50px",
                        padding: "10px",
                        background: "#7df8fb96",
                        border: "none",
                        margin: "15px auto",
                      }}
                    {...register('direction')}
                    />
                          {errors.direction && (
          <p>{errors.direction.message}</p>)}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
               <InputLabel sx={{ fontWeight: 600,color:"white" }}>
                  SELECCIONE UN PLAN*
                </InputLabel>
                <select
                      style={{
                        width: "95%",
                        borderRadius: "50px",
                        height: "50px",
                        padding: "10px",
                        background: "#7df8fb96",
                        border: "none",
                        margin: "15px auto",
                      }}
                     {...register('plan_id')}
                    >
                      {plan.map((item:any, index)=>(  <option value={item.id} key={index}>Plan {`${item.name} de ${item.title} con ${item.type} a ${item.price}$`}</option>))}
                    
                    
                    </select>
                    {errors.plan_id && (
          <p>{errors.plan_id.message}</p>)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
             <InputLabel sx={{ fontWeight: 600,color:"white" }}>
              MENSAJE
            </InputLabel>
            <input
                  style={{
                    width: "98%",
                    borderRadius: "50px",
                    height: "50px",
                    padding: "10px",
                    background: "#7df8fb96",
                    border: "none",
                    margin: "15px auto",
                  }}
                 {...register('message')}
                />
           
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                sx={{ background: "rgb(0 0 0 / 54%)" }}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Snackbars />

    </Paper>
  );
}

export default Form;
