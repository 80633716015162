import React from "react";
import { Typography, Button } from "@mui/material";
import { Grid } from "@mui/material";
import "../../App.css";
import "../../styles.scss";
import "react-slideshow-image/dist/styles.css";
import "../../index.d.ts";
import { HashLink } from "react-router-hash-link";


const Slideshow = () => {
  const poster = require('../../asset/data-center.jpg');
  const bg = require('../../asset/bg.mp4');

  return (
    <section
      style={{
        position: "relative",
        overflow: "hidden",
        height: "100vh",
        width: "100%",
      }}
      id="header"
    >
      <video
        style={{
          width: "100%",
          position: "absolute",
          objectFit: "cover",
          height: "100%",
        }}
        src={bg}
        autoPlay
        muted
        loop
        poster={poster}
      ></video>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          height: "85vh",
          width: "100%",
          position: "absolute",
          zIndex: 101,
        }}
      >
        <Grid item>
          <Typography
            sx={{
              color: "white",
              fontSize: { sm: "40px", xs: "40px", lg: "3.75rem" },
              textAlign: "center",
            }}
          >
            CONÉCTATE SIN LÍMITES
          </Typography>
        </Grid>{" "}
        <Typography variant="h4" sx={{ color: "#ddf90e" }}>
          TE DAMOS +
        </Typography>
        <Grid item>
          {" "}
          <HashLink to="#contact">
            <Button
              variant="contained"
              sx={{
                zIndex: "10",
                padding: "20px",
                fontSize: 20,
                background: "linear-gradient(to right,#e0f907, #00bdd5)",
                color: "#346e86",
                fontWeight: 500,
                ":hover": {
                  background: "rgb(0 0 0 / 54%)", // theme.palette.primary.main
                  color: "white",
                },
              }}
            >
              {" "}
              Contáctanos
            </Button>
          </HashLink>
        </Grid>
      </Grid>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          background: "rgb(0 0 0 / 63%)",
          zIndex: 100,
        }}
      ></div>
    </section>
  );
};
export default Slideshow;
