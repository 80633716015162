import React from "react";
//material ui
import { Grid, Typography } from "@mui/material";
import DiamondIcon from "@mui/icons-material/Diamond";
import DeblurIcon from "@mui/icons-material/Deblur";
import axios from "axios";
import { selectResponse } from "../../app/store";
import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import CardO from "../common/CardO";
import CardC from "../common/CardC";
function PlanOpti() {
  const iconDiamon = () => {
    return <DiamondIcon sx={{ color: "white", fontSize: 40 }} />;
  };
  const [plan, setPlan] = React.useState([]);
  const nResponse = useSelector(selectResponse);
  const limit = 3;
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(1);
  const offset = limit * (page - 1);
  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  async function plansInquiry() {
    return axios
      .get(`plans/?plan_type=fibra&limit=${limit}&offset=${offset}`)
      .then((response) => {
        setPlan(response.data.results);
        if (response.data.count > 0) {
          let count1 = response.data.count / limit;
          setCount(Math.ceil(count1));
        }
      })
      .catch((error) => console.log(error));
  }
  React.useEffect(() => {
    plansInquiry();
    // eslint-disable-next-line
  }, [nResponse, page]);

  return (
    <div data-aos="fade-up">
      <Grid
        container
        sx={{
          width: "100%",
          height: { sm: "auto", md: "auto", lg: "auto" },
          // background: "#F8F9Fa",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
              color: "#346e86",
              fontWeight: 600,
              textAlign: "center",
              pb: "25px",
            }}
          >
            PLANES RESIDENCIALES CON FIBRA ÓPTICA
          </Typography>
        </Grid>
        {plan.map((item: any, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
            <CardC  name={item.name}
            ICon={iconDiamon}
              price={item.price}
              up={item.up}
              down={item.down}/>
          </Grid>
        ))}
         <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Pagination
            count={count}
            color="primary"
            onChange={handleChangePagination}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default PlanOpti;
