import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Footer from "./Footer";

function MorePlan() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        pt: { lg: '170px', md: "170px", sm: "150px", xs: "150px" },
        backgroundImage:'linear-gradient(180deg,#7df8fb 0%, #dbf912 100%)',
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h4" sx={{ color: "rgb(0, 138, 156)", textAlign:'center' }}>
            OTROS PLANES POR REDES MICROONDAS{" "}
          </Typography>
          <hr style={{ border: "2px solid rgb(0, 138, 156)" }} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h5" sx={{ color: "rgb(0, 138, 156)", textAlign:'center', p:'10px',  }}>Planes de internet residencial</Typography>
          <img
            src="https://www.adslsystem.com/wp-content/uploads/2021/06/Planes-internet-residencial-adslsystem.jpg"
            alt="Planes de internet residencia"
            style={{width:'100%'}}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h5" sx={{ color: "rgb(0, 138, 156)", textAlign:'center', p:'10px'  }}>Planes de internet empresarial compartido</Typography>
          <img
            src="https://www.adslsystem.com/wp-content/uploads/2021/06/Planes-internet-empresarial-compartido-adslsystem.jpg"
            alt="Planes de internet empresarial compartido"
            style={{width:'100%'}}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
      </Grid>
      <Footer/>
    </Box>
  );
}

export default MorePlan;
