import React from "react";
import { Paper, Grid, Typography, Button, InputLabel } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
function FormContact() {
  // const [file, setFile] = React.useState<File | null>(null);
  // const [id, setId] = React.useState<string | null>(null);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [open, setOpen] = React.useState(false);
  const [textSnack, setTextSnack] = React.useState<string>(" ");
  const [severity, setSeverity] = React.useState<AlertColor>();



  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  function Snackbars() {
    return (
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {textSnack}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }
  interface formProps {
    phone: string;
    name: string;
    sudject: string;
    content: string;
    email: string;
  }
  const schema: any = yup.object({
    phone: yup.string().required("Este campo es requerido"),
    name: yup.string().required("Este campo es requerido"),
    sudject: yup
      .string()
      .required("Este campo es requerido, ingrese código de referencia"),
      content: yup.string(),
    email: yup
      .string()
      .email("Formato invalido")
      .required("Este campo es requerido"),
  });


    const postPay = async ( data: formProps) => {
    return axios
      .post(`contact/`, data)
      .then((response) => {
        setTextSnack('Se ha enviado de manera exitosa');
        setSeverity("success");
        handleClick();
        reset();
        return response.data;
        
      })
      .catch((error) => {
        console.log(error);
        setTextSnack('ha ocurrido un error, verifique su conexión a internet');
        setSeverity("error");
        handleClick();
      });
  };
  const onSubmit = (data: any) => {
    console.log(data);
   
    postPay(data);
    // reset();
  };
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<formProps>({
    defaultValues: {
      name: "",
      phone: "",
      sudject: "",
      content: "",
      email: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <Paper
    sx={{
      width:{lg:"70%", md:'80%', sm:'100%', xs:'100%'},
      height: "auto",
      m: "auto",
      p: 5,
      zIndex: 2,
      // background: "#7df8fb96",
      background: "rgb(25 118 210 / 50%)",
    }}
    data-aos="fade-up"
    data-aos-duration="900"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Contáctenos  
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
              NOMBRE COMPLETO*
            </InputLabel>

            <input
              {...register("name")}
              name="name"
              style={{
                width: "98%",
                borderRadius: "50px",
                height: "50px",
                padding: "10px",
                background: "#7df8fb96",
                border: "none",
                margin: "15px auto",
              }}
              type="text"
              //   required
              //   title="Por favor ingrese su nombre y apellido"
            />
            {errors.name && <p>{errors.name.message}</p>}
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
                  ASUNTO*
                </InputLabel>
                <input
                  {...register("sudject")}
                  style={{
                    width: "95%",
                    borderRadius: "50px",
                    height: "50px",
                    padding: "10px",
                    background: "#7df8fb96",
                    border: "none",
                    margin: "15px auto",
                  }}
                  type="text"
                />
                {errors.sudject && <p>{errors.sudject.message}</p>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
                  NÚMERO DE TELÉFONO*
                </InputLabel>
                <input
                  style={{
                    width: "98%",
                    borderRadius: "50px",
                    height: "50px",
                    padding: "10px",
                    background: "#7df8fb96",
                    border: "none",
                    margin: "15px auto",
                  }}
                  {...register("phone")}
                />
                {errors.phone && <p>{errors.phone.message}</p>}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
              EMAIL
            </InputLabel>
            <input
              style={{
                width: "98%",
                borderRadius: "50px",
                height: "50px",
                padding: "10px",
                background: "#7df8fb96",
                border: "none",
                margin: "15px auto",
              }}
              {...register("email")}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            sx={{ margin: "20px" }}
          >
            <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
              MENSAJE
            </InputLabel>
            <input
              style={{
                width: "98%",
                borderRadius: "50px",
                height: "50px",
                padding: "10px",
                background: "#7df8fb96",
                border: "none",
                margin: "15px auto",
              }}
              {...register("content")}
            />
          </Grid>
    

          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                sx={{ background: "rgb(0 0 0 / 54%)" }}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Snackbars />
    </Paper>
  );
}

export default FormContact;
