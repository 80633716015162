import React from "react";
//router
import {
  Routes,
  Route,
} from "react-router-dom";
import ContactPlans from "../view/ContactPlans";
import MorePlan from "../view/MorePlan";
import Pay from "../view/Pay";
import View from "../view/View";

export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={<View/>}/>
      <Route path="/otherServices" element={ <MorePlan/>}/>
      <Route path="/pay" element={ <Pay/>}/>
      <Route path="/contactPlans" element={ <ContactPlans/>}/>
    </Routes>
  );
}
