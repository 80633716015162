import React from "react";
import { Paper, Grid, Typography, Button, InputLabel } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
function FormPay() {
  // const [file, setFile] = React.useState<File | null>(null);
  // const [id, setId] = React.useState<string | null>(null);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [open, setOpen] = React.useState(false);
  const [textSnack, setTextSnack] = React.useState<string>(" ");
  const [severity, setSeverity] = React.useState<AlertColor>();



  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  function Snackbars() {
    return (
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {textSnack}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }
  interface formProps {
    nro_contrat: string;
    full_name: string;
    referencia: string;
    message: string;
    // image: File;
    email: string;
  }
  const schema: any = yup.object({
    nro_contrat: yup.string().required("Este campo es requerido"),
    full_name: yup.string().required("Este campo es requerido"),
    referencia: yup
      .string()
      .required("Este campo es requerido, ingrese código de referencia"),
    message: yup.string(),
    email: yup
      .string()
      .email("Formato invalido")
      .required("Este campo es requerido"),
    // image: yup
    //   .mixed()
    //   .required()
    //   .test("fileSize", "La imagen pesa mucho", (value) => {
    //     return value && value[0].size <= 2000000;
    //   }),
  });
  // const onsub = () => {
  //   if (file) {
  //     const idmentira = 1;
  //     const data = new FormData();
  //     data.append("image", file);
  //     console.log(file);
  //     insertFiles(idmentira, data);
  //     // setMessa(false);
  //   } else {
  //     console.log("file no tiene un archivo");
  //     // setMessa(true);
  //     // setTimeout(()=>setMessa(false), 5000);
  //   }
  // };
  // const insertFiles = async (id: any, formData: FormData) => {
  //   return axios
  //     .patch(`rooms/rooms/${id}/`, formData)
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };


    const postPay = async ( data: formProps) => {
    return axios
      .post(`payments/`, data)
      .then((response) => {
        setTextSnack('Se ha enviado de manera exitosa');
        setSeverity("success");
        handleClick();
        reset();
        return response.data;
        
      })
      .catch((error) => {
        console.log(error);
        setTextSnack('ha ocurrido un error, verifique su conexión a internet');
        setSeverity("error");
        handleClick();
      });
  };
  const onSubmit = (data: any) => {
    console.log(data);
   
    postPay(data);
    // reset();
  };
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<formProps>({
    defaultValues: {
      full_name: "",
      nro_contrat: "",
      referencia: "",
      message: "",
      email: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <Paper
      sx={{
        width: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
        height: "auto",
        m: "auto",
        p: { lg: 5, md: 5, sm: 1, xs: 1 },
        zIndex: 2,
        // background: "#7df8fb96",
        background: "#1976d2",
        borderRadius: "15px",
      }}
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              PAGO DE MENSUALIDAD
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
              NOMBRE COMPLETO*
            </InputLabel>

            <input
              {...register("full_name")}
              name="full_name"
              style={{
                width: "98%",
                borderRadius: "50px",
                height: "50px",
                padding: "10px",
                background: "#7df8fb96",
                border: "none",
                margin: "15px auto",
              }}
              type="text"
              //   required
              //   title="Por favor ingrese su nombre y apellido"
            />
            {errors.full_name && <p>{errors.full_name.message}</p>}
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
                  NÚMERO DE CONTRATO
                </InputLabel>
                <input
                  {...register("nro_contrat")}
                  style={{
                    width: "95%",
                    borderRadius: "50px",
                    height: "50px",
                    padding: "10px",
                    background: "#7df8fb96",
                    border: "none",
                    margin: "15px auto",
                  }}
                  type="text"
                />
                {errors.nro_contrat && <p>{errors.nro_contrat.message}</p>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
                  Referencia
                </InputLabel>
                <input
                  style={{
                    width: "98%",
                    borderRadius: "50px",
                    height: "50px",
                    padding: "10px",
                    background: "#7df8fb96",
                    border: "none",
                    margin: "15px auto",
                  }}
                  {...register("referencia")}
                />
                {errors.referencia && <p>{errors.referencia.message}</p>}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
              EMAIL
            </InputLabel>
            <input
              style={{
                width: "98%",
                borderRadius: "50px",
                height: "50px",
                padding: "10px",
                background: "#7df8fb96",
                border: "none",
                margin: "15px auto",
              }}
              {...register("email")}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            sx={{ margin: "20px" }}
          >
            <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
              MENSAJE
            </InputLabel>
            <input
              style={{
                width: "98%",
                borderRadius: "50px",
                height: "50px",
                padding: "10px",
                background: "#7df8fb96",
                border: "none",
                margin: "15px auto",
              }}
              {...register("message")}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            sx={{ margin: "20px" }}
          >
            <input
              type="file"
              accept=".jpeg, .png, .jpg"
              id="contained-button-file"
              onChange={uploadFiles}
              style={{ display: "none" }}
              name="image"
            />
            <InputLabel sx={{ fontWeight: 600, color: "rgb(0 0 0 / 83%)" }}>
              Subir Capture de Referencia
            </InputLabel>
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                sx={{
                  background: "white",
                  ":hover": {
                    bgcolor: "rgb(0 0 0 / 54%)", // theme.palette.primary.main
                    color: "white",
                  },
                }}
                startIcon={<AddPhotoAlternateIcon />}
              >
                Subir Imagen
              </Button>
            </label>
          </Grid> */}

          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                sx={{ background: "rgb(0 0 0 / 54%)" }}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Snackbars />
    </Paper>
  );
}

export default FormPay;
