import { Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { HashLink } from "react-router-hash-link";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        height: ["auto", "auto", "auto", "auto", "auto"],
        background: "linear-gradient(to right,#5ec47d, #265370)",
        padding: "20px",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <img
            src="https://www.adslsystem.com/wp-content/uploads/2019/11/verde.png"
            alt="logo de ADSL SYSTEM"
            style={{ width: "110px", marginRight: "20px" }}
          />
        </Grid>
        <Grid item sx={{}} xs={12} sm={12} md={4} lg={3}>
          <Typography variant="h6" sx={{ color: "white", fontWeight: 600 }}>
            Siguenos en:
          </Typography>
          <Grid container>
            <Grid item>
              <a href="https://www.facebook.com/ADSLSYSTEM.CA/" target="_blank">
                <IconButton>
                  <FacebookIcon sx={{ color: "white", fontSize: "40px" }} />
                </IconButton>
              </a>
            </Grid>
            <Grid item>
              <a
                href="https://www.instagram.com/adslsystem/?hl=es"
                target="_blank"
              >
                <IconButton>
                  <InstagramIcon sx={{ color: "white", fontSize: "40px" }} />
                </IconButton>
              </a>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={3}>
          {" "}
          <Typography variant="h6" sx={{ color: "white", fontWeight: 600 }}>
            Enlaces
          </Typography>
          <HashLink to="/#header">
            <Typography variant="body1" sx={{ color: "white" }}>
              Inicio
            </Typography>
          </HashLink>
          <HashLink to="/#services">
            <Typography variant="body1" sx={{ color: "white" }}>
              Servicios
            </Typography>
          </HashLink>
          <HashLink to="/#planes">
            <Typography variant="body1" sx={{ color: "white" }}>
              Planes
            </Typography>
          </HashLink>
          <HashLink to="/#contact">
            <Typography variant="body1" sx={{ color: "white" }}>
              Contacto
            </Typography>
            </HashLink>
            <HashLink to="/pay">
            <Typography variant="body1" sx={{ color: "white" }}>
              Enviar Pago
            </Typography>
          </HashLink>
          <HashLink to="/contactPlans">
            <Typography variant="body1" sx={{ color: "white" }}>
              Contactar para adquirir un plan
            </Typography>
          </HashLink>
          <a href="https://www.freepik.es/" target="_blank">
            <Typography variant="body1" sx={{ color: "white" }}>
              Recursos
            </Typography>
          </a>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
        <Typography variant="h6" sx={{ color: "white", fontWeight: 600 }}>
           Información de contacto
          </Typography>

            <Typography sx={{color:'white'}} variant="body1">
              <IconButton>
                <LocalPhoneIcon sx={{color:'white'}}/>
              </IconButton>
              0291-6434177 / 0412-5584087
            </Typography>
            <a href="https://goo.gl/maps/RrmF5LnMc3y62EdG6" target="_blank">
            <Typography sx={{color:'white'}} variant="body1">
              <IconButton>
                <LocationOnIcon sx={{color:'white'}}/>
              </IconButton>
             Dirección de nuestra oficina
            </Typography>
            </a>
        </Grid>
        <Grid item xs={12}>
          <hr style={{ borderColor: "white" }} />
          <Typography variant="body1" sx={{ color: "white", fontWeight: 500 }}>
            &copy; 2022 Telecom3, C.A | RIF. J-41311269-8 | Todos los derechos
            reservados | Diseñado y desarrollado por: <a href="https://www.linkedin.com/in/jurlanys-campos/" target="_blanck" style={{textDecoration:"underline"}}>Jurlanys Campos</a> y <a href="https://www.linkedin.com/in/brayanvegas/" target="_blanck" style={{textDecoration:"underline"}}>Brayan Vegas</a>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
