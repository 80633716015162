import { Box } from "@mui/material";
import React from "react";
import Form from "../section/Form";
import Footer from "./Footer";

function ContactPlans() {
  const bg = require("../../asset/mundofondo.jpg");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: { lg: "auto", md: "auto", sm: "auto", xs: "auto" },
          backgroundImage: `url(${bg})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: { lg: "41px", md: "20px" },
        }}
      >
        <Form />
      </Box>

      <Footer />
    </>
  );
}

export default ContactPlans;
