import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import BusinessIcon from "@mui/icons-material/Business";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import BackupIcon from "@mui/icons-material/Backup";
function About() {
  const server = require("../../asset/digital-world.jpg");
  return (
    <Grid
      container
      id="about"
      sx={{
        width: "100%",
        height: { lg: "auto", md: "auto", sm: "auto", xs: "auto",xl:'80vh' },
        background: "white",
        alignContent: " center",
        justifycontent: "center",
        alignItems: "center",
        justifyContent: "center",
        p: {
          xs: "10px",
          sm: "50px",
          md: "20px",
        },
      }}
      data-aos="fade-right"
      data-aos-offset="400"
    >
      <Grid
        item
        lg={4}
        md={6}
        sm={12}
        xs={12}
        sx={{ order: { xs: 2, sm: 2, md: 2, lg: 1 } }}
      >
        <img src={server} alt="" style={{ width: "100%", height: "550px" }} />
      </Grid>
      <Grid
        item
        lg={4}
        md={6}
        sm={12}
        xs={12}
        sx={{ order: { xs: 1, sm: 1, md: 1, lg: 2 } }}
      >
        <Typography
          variant="h4"
          sx={{ color: "rgb(0, 138, 156)", textAlign: "end" }}
        >
          ¿QUIÉNES SOMOS?
        </Typography>
        <hr style={{ border: "2px solid rgb(0, 138, 156)" }} />
        <Grid
          container
          sx={{
            flexWrap: "nowrap",
            alignContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Grid item>
            <Typography variant="body1" sx={{ pl: 2, textAlign: "justify",pr:2 }}>
              Somos ADSL SYSTEM una empresa con más de 10 años de experiencia en
              las Telecomunicaciones e Internet, Hemos venido creciendo e
              innovando de forma exponencial gracias a nuestros clientes y
              contamos con una solida presencia en la región oriental de nuestro
              País. Contamos con un equipo de profesionales con un alto nivel
              humano y dinámico los cuales están en constante crecimiento
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            flexWrap: "nowrap",
            alignContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Grid item>
            <IconButton sx={{ backgroundColor: "#55a7ff", ml: 2, mr: 2 }}>
              <BusinessIcon
                sx={{
                  color: "white",
                  fontSize: "2rem",
                  verticalAlign: "middle",
                }}
              />
            </IconButton>{" "}
          </Grid>
          <Grid item>
            <Typography>Mas de 10 años de experiencia</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            flexWrap: "nowrap",
            alignContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Grid item>
            <IconButton sx={{ backgroundColor: "#55a7ff", ml: 2, mr: 2 }}>
              <DoneAllIcon
                sx={{
                  color: "white",
                  fontSize: "2rem",
                  verticalAlign: "middle",
                }}
              />
            </IconButton>{" "}
          </Grid>
          <Grid item>
            <Typography>Solido equipo profesional</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            flexWrap: "nowrap",
            alignContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Grid item>
            <IconButton sx={{ backgroundColor: "#55a7ff", ml: 2, mr: 2 }}>
              <AccessibilityNewIcon
                sx={{
                  color: "white",
                  fontSize: "2rem",
                  verticalAlign: "middle",
                }}
              />
            </IconButton>{" "}
          </Grid>
          <Grid item>
            <Typography>
              Honestidad, dedicación, atención y responsabilidad
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default About;
