import { Grid, Typography } from "@mui/material";
import React from "react";
import Notice from "../section/Notice";
import axios from "axios";
import { selectResponse } from "../../app/store";
import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
function NoticeView() {
  const img = require("../../asset/mundofondo.jpg");
  const array = [
    {
      title: "Descuento del día del padre",
      image: { img },
      description: "Descuento del dia del padre",
    },
    {
      title: "Descuento del día del padre",
      image: "../../asset/mundofondo.jpg",
      description: "Descuento del dia del padre",
    },
  ];
  const [notice, setNotice] = React.useState([]);
  const nResponse = useSelector(selectResponse);
  const limit = 1;
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(1);
  const offset = limit * (page - 1);
  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  async function plansInquiry() {
    return axios
      .get(`notices/?limit=${limit}&offset=${offset}`)
      .then((response) => {
        setNotice(response.data.results);
        if (response.data.count > 0) {
          let count1 = response.data.count / limit;
          setCount(Math.ceil(count1));
        }
      })
      .catch((error) => console.log(error));
  }
  React.useEffect(() => {
    plansInquiry();
    // eslint-disable-next-line
  }, [nResponse,page]);
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      sx={{
        width: "100%",
        background: "linear-gradient(to right,#e3fe27, #00d9e1)",
        height: { xs: "auto", sm: "auto", md: "auto", lg: "auto" },
        padding: { xs: "20px", sm: "20px", md: "20px", lg: "35px" },
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          NOTICIAS
        </Typography>
        <hr style={{ border: "2px solid white" }} />
      </Grid>
      {notice.map((item: any, index) => (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
          <Notice
            title={item.title}
            image={item.image}
            description={item.description}
          />
        </Grid>
      ))}
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Pagination
          count={count}
          color="primary"
          onChange={handleChangePagination}
        />
      </Grid>
    </Grid>
  );
}

export default NoticeView;
