import React from 'react';
//material ui
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
//route
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from "history";
import Routing from './components/router/Routing';
//component
import Navbar from "./components/common/Navbar";
import './axiosConfig/axios';
import AOS from "aos";
import "aos/dist/aos.css";
export const history = createBrowserHistory();

function App() {

  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
       <BrowserRouter>
      <CssBaseline/>
      <Routing/>
      <Navbar/>
      </BrowserRouter>
  );
}

export default App;
