import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ReactElement } from "react";
import { Slide } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { HashLink } from "react-router-hash-link";
import PayModal from "../section/PayModal";
import logo from "../../asset/logocolor.png";
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
  {name:"Inicio", link:'/#header'},
  {name:"Planes", link:'/#planes'},
  {name:"Servicios", link:'/#services'},
  {name:"Conócenos", link:'/#about'},
  {name:"Fibra", link:'/#fibra'},
  {name:"Contacto", link:'/#contact'},
];

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  interface Props {
    children: ReactElement;
  }
  function HideOnScroll({ children }: Props) {
    const trigger = useScrollTrigger();
    return (
      <Slide appear={false} direction={"down"} in={!trigger}>
        {children}
      </Slide>
    );
  }
  const Cdrawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
       LANYS DEVELOPER
      </Typography>
      <Divider />
      <List>
        {navItems.map((item:any) => (
          <HashLink  to={`${item.link}`}>
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} key={item.name} >
              <ListItemText primary={item.name}  />
            </ListItemButton>
          </ListItem> </HashLink>
        ))}
         <HashLink  to={`/pay`}>
        <ListItem key='itemPay'disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} key='itemPay' >
            <ListItemText primary="Pagar" /> 
            </ListItemButton>
          </ListItem></HashLink>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <HideOnScroll>
      <Box sx={{ display: "flex" }}>
      <AppBar component="nav"  sx={{
        background: "rgb(0 0 0 / 36%)",
        display: "flex",
        flexDirection: "row",
        justifyContent:{ lg:"center",xl:'center',md:'flex-start', sm:'flex-start',xs:'flex-start'},
      }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { xs: "block", sm: "block", md: "block", lg: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              display: { xs: "block", sm: "block", md: "block", lg: "none" },
              width: "100%",
              m: "auto",
            }}
          >
            <img
              src="https://www.adslsystem.com/wp-content/uploads/2019/11/verde.png"
              alt="logo"
              style={{ width: 100 }}
            />
          </Typography>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
              width: "100%",
              margin: "auto",
            }}
          >
            <ul className="menu">
              <li key="header">
                <a href="/#header">Inicio</a>
              </li>
              <li>
                <a href="/#planes" key="planes">Planes</a>
              </li>
              <li>
                <a href="/#services" key="services">Servicios</a>
              </li>
              <li>
                <a href="/#moreservices" key="services+">+Plus</a>
              </li>
              <li>
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: 100 }}
                />
              </li>
              <li>
                <a href="/#about" key="about">Conócenos</a>
              </li>
              <li>
                <a href="/#fibra" key="team">Fibra</a>
              </li>
              <li>
                <a href="/#contact" key="contact">Contacto</a>
              </li>
              <li>
               <PayModal/>
              </li>
            </ul>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {Cdrawer}
        </Drawer>
      </Box>
    </Box>
    </HideOnScroll>
  );
}
