import { Grid } from '@mui/material';
import React from 'react';
import FormPay from '../section/FormPay';
import Footer from './Footer';
function Pay() {
    const bg= require('../../asset/data-center.jpg');
    return (
        <><Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
            width: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
            height: "auto",
            m: "auto",
            p: 10,
            zIndex: 2,
            backgroundImage: `url(${bg})`,
            backgroundSize:'cover',
          }}>
            <Grid item sx={{pt:3}}>
                <FormPay/>
            </Grid>
            
        </Grid>
        <Footer/>
        </>
    );
}

export default Pay;