import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Plan from "../section/Plan";
import PlanOpti from "../section/PlanOpti";

function PlanView() {
  const name= require('../../asset/verde.png');
  const [view, setView] = useState(true);
  return (<Box  
    id="planes"
    sx={{
    width: "100%",
    height:{sm:'auto',md:'auto', lg:'auto', xl:"100vh"},
    // backgroundImage: "linear-gradient( 135deg, #FFF720 10%, #3CD500 100%)",
    backgroundImage: 'linear-gradient(180deg, #e2f900 0%, #7cf8fc 100%)', 
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    flexWrap: 'nowrap',
    paddingTop:'40px',
    paddingBotton:'30px',
  }}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        height: "auto",
      }}
    >
      <Grid item>
        <img
          src={name}
          alt="logo"
          style={{ width: 160 }}
        />
      </Grid>
      <Grid item>
        <Typography variant="h4" sx={{ color: "#346e86", fontWeight:600 }}>
          PLANES
        </Typography>
      </Grid>
      <Grid item>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
              m: 1,
            },
          }}
        >
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              sx={{
                border: "2px solid white",
                fontSize: "18px",
                color: "#346e86",
                fontWeight: 500,
                ":hover": {
                  background: "rgb(0 0 0 / 54%)", // theme.palette.primary.main
                  color: "white",
                },
              }}
              onClick={() => {
                setView(true);
              }}
            >
              {" "}
              FIBRA ÓPTICA
            </Button>
            <Button
              sx={{
                border: "2px solid white",
                fontSize: "18px",
                color: "#346e86",
                fontWeight: 500,
                ":hover": {
                  background: "rgb(0 0 0 / 54%)", // theme.palette.primary.main
                  color: "white",
                },
              }}
              onClick={() => {
                setView(false);
              }}
            >
              {" "}
              REDES MICROONDAS
            </Button>
          </ButtonGroup>
        </Box>
      </Grid>
      
    </Grid>
    {view ? (          
              <PlanOpti />          
          ) : (
             <Grid sx={{width:'100%'}}> <Plan />
          
         </Grid>
          )}
    </Box>
  );
}

export default PlanView;
