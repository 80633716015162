import React from 'react';
import Slideshow from '../section/Header';
import OpServices from '../section/OpServices';
import Services from '../section/Services';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import OtherServices from './OtherServices';
import OurTeam from './OurTeam';
import PlanView from './PlanView';
import '../../index.css';
import NoticeView from './NoticeView';
function View() {
    return (
        <>
           <Slideshow/> 
           <PlanView/>
           <Services/>
           <OtherServices/>
           <About/>
           <OurTeam/>
           <OpServices/>
           <NoticeView/>
           <Contact/>
        
           <Footer/>
        </>
    );
}

export default View;