import { Box } from "@mui/material";
import React from "react";
import Form from "../section/Form";
import FormContact from "../section/FormContact";
function Contact() {
  const bg = require('../../asset/tabletconredes.jpg');
  return (
    <Box
    id="contact"
      sx={{
        width: '100%',
        height:{lg:"auto", md:'auto', sm:'auto',xs:'auto'},
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        p:{lg:'41px',md:'20px'}
      }}
   
    >
 <FormContact/>
    </Box>
  );
}

export default Contact;
