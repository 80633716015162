import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import ContactlessIcon from '@mui/icons-material/Contactless';
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
function OpServices() {
  const image= require('../../asset/fibra-optica-.jpg');
    return (
        <Grid
        container
        id="fibra"
        sx={{
          width: "100%",
          height: {lg:"auto", md:'auto',sm:'auto',xs:'auto',xl:'100vh'},
          background: "white",
          alignContent: " center",
          justifycontent: "center",
          alignItems: "center",
          justifyContent: "center",
          p:{
            xs:'10px', sm:'50px', md:'20px',
          }
        }}
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <Typography variant="h4" sx={{color:'rgb(0, 138, 156)'}}>INTERNET CON FIBRA ÓPTICA</Typography>
          <hr style={{ border: "2px solid rgb(0, 138, 156)" }} />
          <Grid container sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
            <Grid item>
              <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
                <RocketLaunchIcon
                  sx={{
                    color: "white",
                    fontSize: "2rem",
                    verticalAlign: "middle",
                  }}
                />
              </IconButton>{" "}
            </Grid>
            <Grid item>
              <Typography>
             Velocidad y Fiabilidad asegurada 
              </Typography>
            </Grid>
          </Grid>
          <Grid container  sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
            <Grid item>
              <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
                <CameraIndoorIcon 
                  sx={{
                    color: "white",
                    fontSize: "2rem",
                    verticalAlign: "middle",
                  }}
                />
              </IconButton>{" "}
            </Grid>
            <Grid item>
  
              <Typography>
              Mejor calidad de vídeo y audio. 
              </Typography>
            </Grid>
          </Grid>
          <Grid container  sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
            <Grid item>
              <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
                <ContactlessIcon
                  sx={{
                    color: "white",
                    fontSize: "2rem",
                    verticalAlign: "middle",
                  }}
                />
              </IconButton>{" "}
            </Grid>
            <Grid item>
            <Typography> Minimiza interferencias</Typography>
            </Grid>
          </Grid>
          <Grid container  sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
            <Grid item>
              <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
                <SportsEsportsIcon
                  sx={{
                    color: "white",
                    fontSize: "2rem",
                    verticalAlign: "middle",
                  }}
                />
              </IconButton>{" "}
            </Grid>
            <Grid item>
              <Typography> Juegos Online</Typography>
            </Grid>
          </Grid>
          <Grid container  sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
            <Grid item>
              <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
                <YouTubeIcon
                  sx={{
                    color: "white",
                    fontSize: "2rem",
                    verticalAlign: "middle",
                  }}
                />
              </IconButton>{" "}
            </Grid>
            <Grid item>
              <Typography> Peliculas y Videos en alta resolución</Typography>
            </Grid>
          </Grid>
          <Grid container  sx={{ flexWrap: "nowrap", alignContent: 'center', alignItems: 'center',mb:3 }}>
            <Grid item>
              <IconButton sx={{ backgroundColor: "#55a7ff", mr: 2 }}>
                < ConnectedTvIcon
                  sx={{
                    color: "white",
                    fontSize: "2rem",
                    verticalAlign: "middle",
                  }}
                />
              </IconButton>{" "}
            </Grid>
            <Grid item>
              <Typography>Streaming y Video llamadas</Typography>
            </Grid>
          </Grid>
         
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <img
            src={image}
            alt="fibra optica"
            style={{ width: "100%", height:"430px" }}
          />
        </Grid>
  
      </Grid>
    );
}

export default OpServices;