import { Grid } from "@mui/material";
import React from "react";
import DeblurIcon from "@mui/icons-material/Deblur";
import Typography from "@mui/material/Typography";
import CardC from "../common/CardC";
import "../../App.css";
import "../../styles.scss";
// import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
// import ButtonMore from "../common/ButtonMore";
import axios from "axios";
import { selectResponse } from "../../app/store";
import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
function Plan() {
  const [plan, setPlan] = React.useState([]);
  const nResponse = useSelector(selectResponse);
  const limit = 3;
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(1);
  const offset = limit * (page - 1);
  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  async function plansInquiry() {
    return axios 
      .get(`plans/?plan_type=micro&limit=${limit}&offset=${offset}`)
      .then((response) => {
        setPlan(response.data.results);
        if (response.data.count > 0) {
          let count1 = response.data.count / limit;
          setCount(Math.ceil(count1));
        }
      })
      .catch((error) => console.log(error));
  }
  React.useEffect(() => {
    plansInquiry();
    // eslint-disable-next-line
  }, [nResponse, page]);

  const iconDeblur = () => {
    return <DeblurIcon sx={{ color: "white", fontSize: 40 }} />;
  };

 
  return (
    <>
      <Grid
        container
        sx={{
          width: "100%",
          height: ["auto", "auto", "auto"],
          // background: "#F8F9Fa",
          padding: {md:"40px", lg:'10px',xl:'40px',},
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
              color: "#346e86",
              fontWeight: 600,
              textAlign: "center",
              pb: "25px",
            }}
          >
            {" "}
            PLANES RESIDENCIALES REDES MICROONDAS
          </Typography>
        </Grid>
        {plan.map((item: any, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardC
              name={item.name}
              ICon={iconDeblur}
              price={item.price}
              up={item.up}
              down={item.down}
            />
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Pagination
            count={count}
            color="primary"
            onChange={handleChangePagination}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Plan;
