import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IIDRoom {
  idRoom: number | string;
  typeRoom: number | string;
  response: number;
}
const initialState: IIDRoom = {
  idRoom: "",
  typeRoom: "",
  response: 0,
};
export const idSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    updateID: (state, action: PayloadAction<number>) => {
      state.idRoom = action.payload;
      console.log("From store idRoom", state.idRoom);
    },
    updateRoom: (state, action: PayloadAction<string>) => {
      state.typeRoom = action.payload;
      console.log("From store typeRoom", state.typeRoom);
    },
    nResponse: (state) => {
      state.response += 1;
    },
  },
});
export const { updateID, updateRoom,  nResponse } = idSlice.actions;

export const store = configureStore({
  reducer: {
    room: idSlice.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;
export const selectId = (state: RootState) => state.room.idRoom;
export const selectTypeRoom = (state: RootState) => state.room.typeRoom;
export const selectResponse = (state: RootState) => state.room.response;
export default store;
